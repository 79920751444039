import React from 'react';

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
  applyFormattingOnSubintervals,
  getColorBasedOnImprovement,
  improvementPercentage
} from 'helpers';

import { SimplificationActions } from './SimplificationActions';
import { SimplificationScoreInfo } from './SimplificationScoreInfo';
import { useStyles } from '../ParagraphLevelSuggestionsDialog/ParagraphLevelSuggestionsDialog.css';

export const SimplificationItemAccordion = ({
  index,
  sentenceSuggestion,
  sentenceSuggestions,
  setSentenceSuggestions,
  trackMixpanelData,
  handleApplySimplification,
  handleDialogClose,
  setIsExpanded,
  isExpanded,
  selectedText
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const {
    text,
    differences,
    simplificationId,
    suggestions,
    originalParagraphScore,
    newParagraphScore,
    improvement
  } = sentenceSuggestion;

  return (
    <Accordion
      data-testid='accordion'
      onChange={() => setIsExpanded(!isExpanded)}
      sx={{
        boxShadow: 'none',
        width: '100%',
        '&.Mui-expanded': {
          mt: 0,
          mb: '10px !important',
          borderTop: index !== 0 && `1px solid ${theme.palette.gray.light}`
        }
      }}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={
          <IconButton
            disableRipple
            sx={{
              width: '30px',
              height: '30px',
              '&:hover': {
                backgroundColor: theme.palette.gray.lightest
              }
            }}>
            <FontAwesomeIcon icon={faChevronDown} style={{ width: '13px', height: '20px' }} />
          </IconButton>
        }>
        <Typography
          className={isExpanded ? classes.simplifications : classes.simplificationsCollapsed}>
          {isExpanded ? applyFormattingOnSubintervals(theme, differences, suggestions, text) : text}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box sx={{ display: 'flex', paddingLeft: '15px' }}>
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <SimplificationScoreInfo scoreType='original' score={originalParagraphScore} />
            <SimplificationScoreInfo scoreType='suggested' score={newParagraphScore} />
          </Box>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamilyPrimarySemibold,
              color: getColorBasedOnImprovement(improvement, theme),
              fontSize: theme.typography.pxToRem(12),
              alignSelf: 'center',
              marginLeft: '5px'
            }}>
            {improvementPercentage(improvement)}%
          </Typography>
        </Box>
        <SimplificationActions
          suggestionText={text}
          simplificationId={simplificationId}
          handleApplySuggestion={handleApplySimplification}
          handleDialogClose={handleDialogClose}
          sentenceSuggestions={sentenceSuggestions}
          setSentenceSuggestions={setSentenceSuggestions}
          trackMixpanelData={trackMixpanelData}
          selectedText={selectedText}
        />
      </AccordionDetails>
    </Accordion>
  );
};

SimplificationItemAccordion.propTypes = {
  index: PropTypes.number,
  sentenceSuggestion: PropTypes.object,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  trackMixpanelData: PropTypes.func,
  handleApplySimplification: PropTypes.func,
  handleDialogClose: PropTypes.func,
  setIsExpanded: PropTypes.func,
  isExpanded: PropTypes.bool,
  selectedText: PropTypes.string
};
