import React, { useEffect, useRef, useState } from 'react';

import { Chip, Grid, IconButton, Popover, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Icons } from 'components';

import './LabelChips.css';

export const LabelChips = ({ labels, showEditButton = false, setIsMenuOpen }) => {
  const theme = useTheme();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [maxTextWidth, setMaxTextWidth] = useState(0);

  const CHIP_FONT_SIZE = 14;
  const CHIP_MARGIN_LEFT = 8;
  const CHIP_LABEL_PADDING = 12;
  const MAX_CIRCLE_WIDTH = 38;
  const CIRCLE_MARGIN_LEFT = 6;
  const displayedChipIds = [];
  let occupiedWidth = 0;

  const useStyles = makeStyles({
    circleItem: {
      width: 25,
      height: 25,
      cursor: 'default',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: theme.palette.gray.light,
      '&:hover': {
        backgroundColor: theme.palette.gray.light
      }
    },
    circleItemMargin: {
      marginLeft: CIRCLE_MARGIN_LEFT
    },
    fontStyle: {
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamilyPrimaryMedium,
      color: isOpen ? theme.palette.gray.darker : theme.palette.gray.main
    }
  });
  const classes = useStyles();

  useEffect(() => {
    setMaxTextWidth(getTextMaxWidth());

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref.current]);

  const estimateTextWidth = text => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = `${CHIP_FONT_SIZE}px ${theme.typography.fontFamilyPrimaryRegular}`;
      context.letterSpacing = '0.15p';
      return context.measureText(text).width;
    }
  };

  const shouldChipBeDisplayed = (label, index) => {
    if (!ref.current) {
      return false;
    }
    const leftMargin = displayedChipIds.length === 0 ? 0 : CHIP_MARGIN_LEFT;
    const labelWidth = estimateTextWidth(label.name) + 2 * CHIP_LABEL_PADDING + leftMargin;
    occupiedWidth += labelWidth;
    const maxWidth =
      index === labels.length - 1
        ? getContainerMaxWidth()
        : getContainerMaxWidth() - MAX_CIRCLE_WIDTH - CIRCLE_MARGIN_LEFT;

    if (occupiedWidth < maxWidth || displayedChipIds.length === 0) {
      displayedChipIds.push(label.id);
      return true;
    }

    return false;
  };

  const getContainerMaxWidth = () => {
    if (showEditButton) {
      return (
        ref.current?.parentElement?.offsetWidth -
        ref.current?.parentElement?.children[1]?.offsetWidth -
        ref.current?.parentElement?.children[2]?.offsetWidth
      );
    } else {
      return (
        ref.current?.parentElement?.offsetWidth -
        ref.current?.parentElement?.children[1]?.offsetWidth
      );
    }
  };

  const getTextMaxWidth = () => {
    return getContainerMaxWidth() - 2 * CHIP_LABEL_PADDING - CHIP_MARGIN_LEFT;
  };

  const handleResize = () => {
    setMaxTextWidth(getTextMaxWidth());
  };

  const numberOfNotDisplayedLabels = () => {
    return ref.current ? labels.length - displayedChipIds.length : 0;
  };

  const getPopupAnchorElement = () => {
    return ref.current?.parentElement?.children[1]?.children[0];
  };

  const getChipLeftMargin = () => {
    return displayedChipIds.length === 1 ? '0px' : `${CHIP_MARGIN_LEFT}px`;
  };

  return (
    <div>
      <Grid
        container
        spacing={0}
        alignItems='center'
        display='flex'
        flexWrap='nowrap'
        direction='row'
        data-testid='labelChips'>
        <Grid ref={ref} item display='flex' flexWrap='nowrap' flexDirection='row' overflow='hidden'>
          {labels.map((label, index) => {
            return (
              shouldChipBeDisplayed(label, index) && (
                <Chip
                  key={label.id}
                  label={
                    <Typography
                      sx={{
                        maxWidth: maxTextWidth,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: theme.typography.fontFamilyPrimaryRegular,
                        color: theme.palette.gray.main,
                        fontSize: theme.typography.pxToRem(CHIP_FONT_SIZE)
                      }}>
                      {label.name}
                    </Typography>
                  }
                  sx={{
                    height: '25px',
                    ml: getChipLeftMargin(),
                    background: theme.palette.gray.lighter
                    // eslint-disable-next-line prettier/prettier
                  }}>
                </Chip>
              )
            );
          })}
        </Grid>
        <Grid
          item
          maxWidth={`${MAX_CIRCLE_WIDTH}px`}
          minHeight='30px'
          display='flex'
          alignItems='center'
          justifyContent='center'>
          {numberOfNotDisplayedLabels() > 0 && (
            <div
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              className={clsx(classes.circleItem, classes.circleItemMargin)}>
              <p className={classes.fontStyle}>+{numberOfNotDisplayedLabels()}</p>
            </div>
          )}
        </Grid>
        {showEditButton && (
          <Grid
            item
            width='38px'
            minHeight='30px'
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <div className={clsx(classes.circleItem, 'editLabelsIcon')}>
              <IconButton data-testid='editLabelsButton' onClick={() => setIsMenuOpen(true)}>
                <Icons iconName={'editLabels'} fill='current' />
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>
      {numberOfNotDisplayedLabels() > 0 && (
        <Popover
          open={isOpen}
          anchorEl={getPopupAnchorElement()}
          sx={{ pointerEvents: 'none' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              mt: theme.spacing(0.5),
              p: '15px',
              bgcolor: theme.palette.gray.white,
              minWidth: '120px'
            }
          }}>
          <Grid container alignItems='center' display='flex'>
            <Grid item display='flex' flexDirection='column'>
              {labels.map(label => {
                return (
                  !displayedChipIds.includes(label.id) && (
                    <Stack
                      align='left'
                      direction='column'
                      key={label.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                      }}>
                      <Typography
                        key={label.id}
                        sx={{
                          fontFamily: theme.typography.fontFamilyPrimaryRegular,
                          color: theme.palette.primary.main,
                          fontWeight: theme.typography.fontWeightRegular,
                          fontSize: theme.typography.pxToRem(12)
                        }}>
                        {label.name}
                      </Typography>
                    </Stack>
                  )
                );
              })}
            </Grid>
          </Grid>
        </Popover>
      )}
    </div>
  );
};

LabelChips.propTypes = {
  labels: PropTypes.array,
  showEditButton: PropTypes.bool,
  setIsMenuOpen: PropTypes.func
};
