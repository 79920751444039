import React from 'react';
import { useTranslation } from 'react-i18next';

import CancelIcon from '@mui/icons-material/Cancel';
import { Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { formatFilterChipText } from 'helpers';

export const SelectedFilterDisplayChip = ({ handleDelete, item, dataTestId }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const chipText = formatFilterChipText(item, t);

  return (
    <Chip
      sx={{
        my: 1,
        mr: 1,
        height: '24px',
        width: 'auto',
        px: 1,
        bgcolor: theme.palette.gray.lightest
      }}
      deleteIcon={
        <CancelIcon
          style={{
            color: theme.palette.grey[400],
            width: '14px',
            height: '14px'
          }}
        />
      }
      label={
        <Typography
          sx={{
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            color: theme.palette.gray.main,
            fontSize: theme.typography.pxToRem(14)
          }}>
          {chipText}
        </Typography>
      }
      onDelete={() => handleDelete(item.category, item.filterOption)}
      data-testid={dataTestId}
    />
  );
};

SelectedFilterDisplayChip.propTypes = {
  handleDelete: PropTypes.func,
  item: PropTypes.object,
  dataTestId: PropTypes.string
};
