import React, { useContext, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { checkFeatureFlag, featureFlagsEnum, lazyRetry, toastMessage } from 'helpers';
import { DocumentStateProvider, UserContext, WebSocketConnectionProvider } from 'providers';

import { Loadable } from './Loadable';

const DocumentEditorPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/DocumentEditorPage/DocumentEditorPage')))
);
const DocumentEditPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/DocumentEditPage/DocumentEditPage')))
);

export const DocumentRedirection = ({ isNewEditor }) => {
  const [t] = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { featureFlags } = useContext(UserContext);
  const { id } = useParams();

  if (isNaN(id)) {
    toastMessage(enqueueSnackbar, closeSnackbar, t('InvalidContentId'), 'warning');
    return <Navigate to='/content' replace />;
  }

  if (isNewEditor && checkFeatureFlag(featureFlags, featureFlagsEnum.DOCUMENT_EDITOR)) {
    return (
      <DocumentStateProvider documentId={Number(id)}>
        <DocumentEditorPage />
      </DocumentStateProvider>
    );
  } else {
    return (
      <WebSocketConnectionProvider id={id}>
        <DocumentEditPage />
      </WebSocketConnectionProvider>
    );
  }
};

DocumentRedirection.propTypes = {
  isNewEditor: PropTypes.bool
};
