import { editorSidebarOptions } from 'helpers/enums';

const INITIAL_SUGGESTIONS_FILTER_OPTIONS = {
  category: [],
  impact: [3] // High impact by default
};

const initialState = {
  isReconnecting: false,
  isRescoring: false,
  isScoreImproved: false,
  isFetchingDocumentSuggestions: false,
  isFetchingReplacements: false,
  isFetchingSimplifications: false,
  isFetchingSelectedTextSimplifications: false,
  isParagraphDialogOpen: false,
  isRephraseSelected: false,
  previouslyClickedSection: null,
  undoRedoStack: { undo: [], redo: [] },
  selectedMode: editorSidebarOptions.SIMPLIFICATION,
  selectedSuggestionFilterOptions: INITIAL_SUGGESTIONS_FILTER_OPTIONS,
  filteredDocumentSuggestions: [],
  selectedSimplification: null,
  isPendingReload: false,
  pendingSuggestions: [],
  quillEditor: null,
  expandedSuggestion: null,
  replacementsInfo: {},
  simplifications: {},
  selectedComplexSentence: { index: 0, length: 0 },
  areReplacementsReceived: false,
  expandedAccordion: null,
  isRephraseDialogOpen: false,
  isReplacementDialogOpen: false,
  selectedText: null
};

export const editorDataStore = set => ({
  ...initialState,
  setIsReconnecting: value => set({ isReconnecting: value }),
  setIsRescoring: value => set({ isRescoring: value }),
  setIsScoreImproved: value => set({ isScoreImproved: value }),
  setIsFetchingDocumentSuggestions: value => set({ isFetchingDocumentSuggestions: value }),
  setIsFetchingReplacements: value => set({ isFetchingReplacements: value }),
  setIsFetchingSimplifications: value => set({ isFetchingSimplifications: value }),
  setIsFetchingSelectedTextSimplifications: value =>
    set({ isFetchingSelectedTextSimplifications: value }),
  setIsParagraphDialogOpen: value => set({ isParagraphDialogOpen: value }),
  setIsRephraseSelected: value => set({ isRephraseSelected: value }),
  setPreviouslyClickedSection: value => set({ previouslyClickedSection: value }),
  setUndoRedoStack: value => set({ undoRedoStack: value }),
  setSelectedMode: value => {
    set({ selectedMode: value });
    if (value === editorSidebarOptions.COMMENTS) {
      set({
        isRephraseDialogOpen: false,
        isReplacementDialogOpen: false,
        isRephraseSelected: false,
        selectedText: null
      });
    }
  },
  setSelectedSimplification: value => set({ selectedSimplification: value }),
  setIsPendingReload: value => set({ isPendingReload: value }),
  setPendingSuggestions: value => set({ pendingSuggestions: value }),
  resetEditorDataStore: () => set(initialState),
  setQuillEditor: value => set({ quillEditor: value }),
  setExpandedSuggestion: value => set({ expandedSuggestion: value }),
  setReplacementsInfo: value => set({ replacementsInfo: value }),
  setSimplifications: value => set({ simplifications: value }),
  setSelectedComplexSentence: value => set({ selectedComplexSentence: value }),
  setAreReplacementsReceived: value => set({ areReplacementsReceived: value }),
  setExpandedAccordion: value => set({ expandedAccordion: value }),
  setIsRephraseDialogOpen: value => set({ isRephraseDialogOpen: value }),
  setIsReplacementDialogOpen: value => set({ isReplacementDialogOpen: value }),
  setSelectedText: value => set({ selectedText: value })
});
