import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Chip, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { sortLabels } from 'helpers';

export const LabelChipWithCloseButton = ({
  labels,
  containerWidth,
  backgroundColor,
  handleDelete,
  removePadding = false
}) => {
  const theme = useTheme();

  const sortedLabels = sortLabels(labels);

  return (
    <Container
      disableGutters={removePadding}
      sx={{
        display: 'flex',
        maxWidth: '100% !important',
        width: containerWidth,
        pb: '10px',
        pl: 0,
        mr: 1,
        ml: 0,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}>
      {sortedLabels.map(label => (
        <Chip
          sx={{
            mb: '10px',
            mr: 1,
            height: '25px',
            width: 'auto',
            px: 1,
            border: '2px solid ' + theme.palette.gray.light,
            backgroundColor: backgroundColor
          }}
          deleteIcon={
            <CloseIcon
              style={{
                color: theme.palette.gray.main,
                fontSize: theme.typography.pxToRem(12)
              }}
            />
          }
          key={label.id}
          label={
            <Typography
              sx={{
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                color: theme.palette.gray.main,
                fontSize: theme.typography.pxToRem(14)
              }}>
              {label.name}
            </Typography>
          }
          onDelete={() => handleDelete(label.id)}
        />
      ))}
    </Container>
  );
};

LabelChipWithCloseButton.propTypes = {
  labels: PropTypes.array,
  containerWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
  handleDelete: PropTypes.func,
  removePadding: PropTypes.bool
};
