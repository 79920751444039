import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  headerItem: {
    height: '50px',
    paddingLeft: '12px'
  },
  menuItem: props => ({
    height: '50px',
    fontFamily: props.typography.fontFamilyPrimaryMedium,
    fontSize: props.typography.pxToRem(14),
    color: props.palette.primary.main,
    paddingLeft: '0px',
    paddingRight: '0px',
    '&.Mui-selected': {
      backgroundColor: props.palette.gray.white
    },
    '&:hover': {
      backgroundColor: props => props.palette.gray.white
    }
  }),
  menuItemSearchBox: {
    marginBottom: '12px',
    borderRadius: '8px'
  },
  onItemHover: props => ({
    '&:hover': {
      backgroundColor: props.palette.gray.lightest + ' !important',
      color: props.palette.primary.main
    }
  }),
  menuItemCheckBox: {
    paddingLeft: '0px'
  },
  borderBottom: {
    borderBottom: props => '0.5px solid ' + props.palette.gray.lighter
  },
  inputLabelNoShrink: {
    transform: 'translate(10px, 13.5px)'
  },
  menuItemButtons: {
    height: '60px',
    '&:hover': {
      backgroundColor: props => props.palette.gray.white
    }
  }
});
