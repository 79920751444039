import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Checkbox,
  Grid,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Icons, PrimaryButton, Scrollbar } from 'components';
import { filterSearchedProperties, handleSearch } from 'helpers';
import { handleLabelChange, isLabelMenuItemChecked } from 'helpers/methods.js';
import { UserContext } from 'providers';
import { ReactComponent as FadedLabelIcon } from 'resources/images/fadedLabel.svg';

import { useStyles } from './LabelSelection.css.js';
import './Labels.css';
import { SearchMenuItem } from './SearchMenuItem.js';

export const LabelMenu = ({
  existingLabels,
  selectedLabels,
  setSelectedLabels,
  setIsChangeLabelInProgress,
  showBackButtonItem = true,
  overrideMenuStyle = false,
  searchBoxMenuOverrideStyle = {},
  showApplyAndClearAllButtons = false,
  applyMultiLabelSelection,
  showAlreadyAssignedLabels,
  labelsOnSelectedDocuments,
  dataTestId
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [t] = useTranslation('common');

  const [search, setSearch] = useState('');
  const [labels, setLabels] = useState([]);

  const { labels: contextLabels } = useContext(UserContext);

  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    const assignedLabels =
      (showAlreadyAssignedLabels && labelsOnSelectedDocuments) || contextLabels;
    setLabels(filterSearchedProperties(assignedLabels, search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextLabels, search, labelsOnSelectedDocuments]);

  const searchBoxMenuStyle = {
    width: '270px',
    borderRadius: '0px',
    fontFamily: theme.typography.fontFamilyPrimaryMedium,
    fontSize: theme.typography.pxToRem(12)
  };

  const menuItems = () => {
    return (
      <Scrollbar sx={{ height: '250px', minHeight: '50px' }}>
        {labels?.map(item => (
          <MenuItem
            onClick={() => handleLabelChange(item, selectedLabels, setSelectedLabels)}
            disableRipple
            className={clsx(classes.borderBottom, classes.menuItem, classes.onItemHover, 'icon')}
            key={item.id}
            value={item}
            data-testid={`${dataTestId}-${item.id}`}>
            <Checkbox checked={isLabelMenuItemChecked(item, existingLabels, selectedLabels)} />
            <ListItemIcon sx={{ px: 1 }}>
              <FadedLabelIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                ml: '10px',
                overflow: 'hidden',
                mr: '20px',
                textOverflow: 'ellipsis'
              }}
              primary={item.name}
            />
          </MenuItem>
        ))}
      </Scrollbar>
    );
  };

  return (
    <>
      {showBackButtonItem && (
        <MenuItem
          disableRipple
          className={classes.headerItem}
          onClick={() => setIsChangeLabelInProgress(false)}>
          <ListItemIcon>
            <Icons iconName={'grayBack'} />
          </ListItemIcon>
          <Typography variant='body2' sx={{ fontFamily: theme.typography.fontFamilyPrimaryMedium }}>
            {t('backToMenu')}
          </Typography>
        </MenuItem>
      )}
      <SearchMenuItem
        search={search}
        searchValue={searchValue}
        setSearch={setSearch}
        setSearchValue={setSearchValue}
        handleSearch={handleSearch}
        component='customOutlinedInput'
        additionalMenuClass={overrideMenuStyle ? classes.menuItemSearchBox : ''}
        style={
          Object.keys(searchBoxMenuOverrideStyle).length
            ? searchBoxMenuOverrideStyle
            : searchBoxMenuStyle
        }
        placeholder='searchLabels'
        onKeyDownHandler={e => e.stopPropagation()}
      />
      {overrideMenuStyle ? <Card sx={{ borderRadius: '8px' }}>{menuItems()}</Card> : menuItems()}
      {showApplyAndClearAllButtons && (
        <MenuItem
          disableRipple
          className={clsx(classes.menuItem, classes.menuItemButtons)}
          sx={{ margin: '10px 0px' }}>
          <Grid
            container
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <PrimaryButton
              dataTestId='applyMultiLabelButton'
              isDisabled={!selectedLabels?.length}
              handleClick={() => applyMultiLabelSelection([], showAlreadyAssignedLabels)}
              buttonText={t('apply')}
              width={'45%'}
            />
            <PrimaryButton
              variant='white'
              buttonText={t('clearAll')}
              width={'45%'}
              handleClick={() => {
                setSelectedLabels([]);
              }}
            />
          </Grid>
        </MenuItem>
      )}
    </>
  );
};

LabelMenu.propTypes = {
  existingLabels: PropTypes.array,
  selectedLabels: PropTypes.array,
  setSelectedLabels: PropTypes.func,
  setIsChangeLabelInProgress: PropTypes.func,
  showBackButtonItem: PropTypes.bool,
  overrideMenuStyle: PropTypes.bool,
  searchBoxMenuOverrideStyle: PropTypes.object,
  showApplyAndClearAllButtons: PropTypes.bool,
  applyMultiLabelSelection: PropTypes.func,
  showAlreadyAssignedLabels: PropTypes.bool,
  labelsOnSelectedDocuments: PropTypes.array,
  dataTestId: PropTypes.string
};
