import { useCallback, useContext, useRef, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Delta from 'quill-delta';

import {
  displayBorder,
  filterSuggestionsPerRange,
  getSuggestionMappings,
  MESSAGE_TYPES,
  suggestionActionTypesEnum,
  suggestionFormats,
  updateSuggestionsOnChange
} from 'helpers';
import { DocumentStateContext } from 'providers';
import { useStoreFields } from 'stores';

export const useSimplifications = ({ quillEditor }) => {
  const theme = useTheme();
  const [selectedTextSuggestions, setSelectedTextSuggestions] = useState([]);
  const selection = useRef({ index: -1, length: 0 });
  const isMobile = useMediaQuery(theme.breakpoints.down(displayBorder.small));
  const suggestionMappings = getSuggestionMappings(theme);

  const documentText = quillEditor?.getText();
  const { connection } = useContext(DocumentStateContext);

  const {
    documentId,
    documentSuggestions,
    setDocumentSuggestions,
    isRephraseSelected,
    setIsFetchingSelectedTextSimplifications,
    filteredDocumentSuggestions,
    setSelectedSimplification
  } = useStoreFields([
    'documentId',
    'documentSuggestions',
    'setDocumentSuggestions',
    'isRephraseSelected',
    'setIsFetchingSelectedTextSimplifications',
    'filteredDocumentSuggestions',
    'setSelectedSimplification'
  ]);

  // filtered document suggestions based on their action type
  const filterSuggestionsPerActionTypes = (
    documentSuggestions,
    primaryActionType,
    secondaryActionType
  ) => {
    const primarySuggestions = documentSuggestions.filter(
      item => item.action === primaryActionType
    );

    return documentSuggestions.filter(item => {
      if (item.action === primaryActionType) {
        return true;
      }
      // all occurrences of complex entities outside of specific index range
      return !primarySuggestions.some(primarySuggestion => {
        const withinRange =
          item.suggestionRange.startIndex >= primarySuggestion.suggestionRange.startIndex &&
          item.suggestionRange.endIndex <= primarySuggestion.suggestionRange.endIndex;

        return secondaryActionType
          ? item.action === secondaryActionType && withinRange
          : withinRange;
      });
    });
  };

  const determineUnderliningType = (
    isPrimarySuggestionSelected,
    isSecondarySuggestionSelected,
    mainSuggestionFormat,
    secondarySuggestionFormat
  ) => {
    if (isPrimarySuggestionSelected) {
      return mainSuggestionFormat.toString();
    }
    if (isSecondarySuggestionSelected) {
      return secondarySuggestionFormat.toString();
    }
    return null;
  };

  const underlineSuggestions = useCallback(
    suggestion => {
      removeAllHighlighting();

      if (isRephraseSelected) {
        return;
      }

      const filteredDocumentComplexSentences = filterSuggestionsPerActionTypes(
        filteredDocumentSuggestions,
        suggestionActionTypesEnum.REMOVE_COMPLEX_SENTENCE,
        null
      );

      const delta = new Delta();
      let currentIndex = 0;

      filteredDocumentComplexSentences?.forEach(sugg => {
        const mainSuggestionFormat = suggestionMappings[sugg.action].format;
        const isSuggestionSelected = sugg.suggestionId === suggestion?.suggestionId;

        if (sugg.suggestionRange.startIndex >= currentIndex) {
          // retain everything from document start until first suggestion and between suggestions
          delta.retain(sugg.suggestionRange.startIndex - currentIndex);
          currentIndex = sugg.suggestionRange.startIndex;
        } else {
          // temporary fix for passive voice suggestions containing word-level suggestions
          return;
        }

        if (suggestion && sugg.targetType === 2 && sugg.sentenceId === suggestion.sentenceId) {
          const complexEntitiesInSentenceRange = filterSuggestionsPerRange(
            sugg.suggestionRange.startIndex,
            sugg.suggestionRange.endIndex,
            filteredDocumentSuggestions
          );

          for (let i = 1; i < complexEntitiesInSentenceRange.length; i++) {
            const inSentenceSuggestion = complexEntitiesInSentenceRange[i];
            const inSentenceSuggestionFormat =
              suggestionMappings[inSentenceSuggestion.action].format;

            if (inSentenceSuggestion.suggestionRange.startIndex > currentIndex) {
              // selected sentence parts without marked words/phrases
              delta.retain(inSentenceSuggestion.suggestionRange.startIndex - currentIndex, {
                [mainSuggestionFormat]: {
                  active: true,
                  isRephraseSelected: isRephraseSelected,
                  underliningType: determineUnderliningType(
                    isSuggestionSelected,
                    false,
                    mainSuggestionFormat,
                    null
                  )
                }
              });
            }

            // word/phrase suggestions inside selected sentence
            delta.retain(inSentenceSuggestion.suggestionRange.length, {
              [inSentenceSuggestionFormat]: {
                active: true,
                isRephraseSelected: isRephraseSelected,
                underliningType: determineUnderliningType(
                  isSuggestionSelected,
                  inSentenceSuggestion.suggestionId === suggestion.suggestionId,
                  mainSuggestionFormat,
                  inSentenceSuggestionFormat
                )
              }
            });
            currentIndex = inSentenceSuggestion.suggestionRange.endIndex;
          }

          if (sugg.suggestionRange.endIndex > currentIndex) {
            // end of selected sentence after last word/phrase suggestion
            delta.retain(sugg.suggestionRange.endIndex - currentIndex, {
              [mainSuggestionFormat]: {
                active: true,
                isRephraseSelected: isRephraseSelected,
                underliningType: determineUnderliningType(
                  isSuggestionSelected,
                  false,
                  mainSuggestionFormat,
                  null
                )
              }
            });
            currentIndex = sugg.suggestionRange.endIndex;
          }
          return;
        }

        // highlight suggestion
        delta.retain(sugg.suggestionRange.endIndex - currentIndex, {
          [mainSuggestionFormat]: {
            active: isSuggestionSelected,
            isRephraseSelected: isRephraseSelected,
            underliningType: mainSuggestionFormat.toString()
          }
        });
        currentIndex = sugg.suggestionRange.endIndex;
      });

      quillEditor?.updateContents(delta);
    },
    [quillEditor, filteredDocumentSuggestions, isRephraseSelected]
  );

  const onEditorClick = () => {
    if (isMobile || isRephraseSelected) {
      return undefined;
    }

    if (selection.current?.length === 0) {
      // There can be multiple suggestions on current selection (both sentence and word)
      const matchingSuggestions = filteredDocumentSuggestions.filter(
        suggestion =>
          selection.current.index >= suggestion.suggestionRange.startIndex &&
          selection.current.index < suggestion.suggestionRange.endIndex
      );

      if (matchingSuggestions.length > 0) {
        // User should be able to click on a word level suggestion inside of a sentence with sentence level suggestion
        const selectedSuggestion = matchingSuggestions[matchingSuggestions.length - 1];
        underlineSuggestions(selectedSuggestion, false);
        setSelectedSimplification(selectedSuggestion);
      } else {
        underlineSuggestions();
        setSelectedSimplification(null);
      }
    }
  };

  const removeAllHighlighting = () => {
    suggestionFormats.forEach(format => {
      quillEditor?.formatText(0, quillEditor.getText().length, format, false);
    });
  };

  const removeSuggestionsDueToManualTextChange = delta => {
    const newSuggestions = updateSuggestionsOnChange(documentSuggestions, delta);
    if (newSuggestions.length !== documentSuggestions.length) {
      // temporary lag solution
      setDocumentSuggestions(newSuggestions);
    }
  };

  const highlightSelectedText = (startIndex, length, shouldHighlight = true) => {
    quillEditor.formatText(startIndex, length, 'paragraph_suggestion', shouldHighlight);
  };

  const getSelectedTextSuggestions = () => {
    setIsFetchingSelectedTextSimplifications(true);
    const textSelection = quillEditor?.getSelection();
    if (textSelection && documentText) {
      highlightSelectedText(textSelection.index, textSelection.length);
      const selectedText = documentText.substring(
        textSelection.index,
        textSelection.index + textSelection.length
      );
      connection
        ?.invoke(
          MESSAGE_TYPES.GET_SELECTED_TEXT_SUGGESTIONS,
          0,
          selectedText,
          textSelection.index,
          textSelection.index + textSelection.length,
          documentId
        )
        .then(resp => {
          setSelectedTextSuggestions(resp.simplifications);
          resp.simplifications && setIsFetchingSelectedTextSimplifications(false);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  return {
    onEditorClick,
    underlineSuggestions,
    selection,
    removeSuggestionsDueToManualTextChange,
    removeAllHighlighting,
    getSelectedTextSuggestions,
    selectedTextSuggestions,
    setSelectedTextSuggestions
  };
};
